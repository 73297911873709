"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  token: function token(state) {
    return state.user.token;
  },
  user: function user(state) {
    return state.user.user;
  },
  number: function number(state) {
    return state.test.number;
  }
};
var _default = getters;
exports.default = _default;