"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.typed.uint8-array");

require("core-js/modules/es6.regexp.match");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

var _default = {
  methods: {
    /**
     * 检查并压缩图片大小
     */
    checkAndHandleCompression: function checkAndHandleCompression(file) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.imgBase64(file, function (image, canvas) {
          var maxSize = 2 * 1024; // 2M (单位KB)

          var fileSize = file.size / 1024; // 图片大小 (单位KB)

          var uploadSrc, uploadFile; // 如果图片大小大于maxSize，进行压缩

          if (fileSize > maxSize) {
            uploadSrc = canvas.toDataURL(file.type, maxSize / fileSize); // 转换成DataURL

            uploadFile = _this.convertBase64UrlToFile(uploadSrc, file.name); // 转成file文件
          } else {
            uploadSrc = image.src;
            uploadFile = file;
          }

          var compressedSize = uploadFile.size / 1024; // 压缩后图片大小 (单位KB)
          // 判断图片大小是否小于maxSize，如果大于则继续压缩至小于为止

          if (compressedSize.toFixed(2) > maxSize) {
            _this.checkAndHandleCompression(uploadFile);
          } else {
            var fileOptions = {
              uploadSrc: uploadSrc,
              uploadFile: uploadFile
            };
            resolve(fileOptions);
          }
        });
      });
    },

    /**
     * 将图片转化为base64
     */
    imgBase64: function imgBase64(file, callback) {
      // 看支持不支持FileReader
      if (!file || !window.FileReader) return; // 创建一个 Image 对象

      var image = new Image(); // 绑定 load 事件处理器，加载完成后执行

      image.onload = function () {
        // 创建 canvas DOM 对象
        var canvas = document.createElement('canvas'); // 返回一个用于在画布上绘图的环境, '2d' 指定了您想要在画布上绘制的类型

        var ctx = canvas.getContext('2d'); // 如果高度超标 // 参数，最大高度

        var MAX_HEIGHT = 3000;

        if (image.height > MAX_HEIGHT) {
          // 宽度等比例缩放 *=
          image.width *= MAX_HEIGHT / image.height;
          image.height = MAX_HEIGHT;
        } // 获取 canvas的 2d 环境对象,
        // 可以理解Context是管理员，canvas是房子


        ctx.clearRect(0, 0, canvas.width, canvas.height); // 重置canvas宽高

        canvas.width = image.width;
        canvas.height = image.height; // 将图像绘制到canvas上

        ctx.drawImage(image, 0, 0, image.width, image.height);
        callback(image, canvas);
      };

      if (/^image/.test(file.type)) {
        // 创建一个reader
        var reader = new FileReader(); // 读取成功后的回调

        reader.onload = function () {
          // 设置src属性，浏览器会自动加载。
          // 记住必须先绑定事件，才能设置src属性，否则会出同步问题。
          image.src = this.result;
        }; // 将图片将转成 base64 格式


        reader.readAsDataURL(file);
      }
    },

    /**
     * 把Base64转换成file文件
     */
    convertBase64UrlToFile: function convertBase64UrlToFile(dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {
        type: mime
      });
    }
  }
};
exports.default = _default;