"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  number: 1
};
var actions = {
  onePlusAsync: function onePlusAsync(_ref, _ref2) {
    var commit = _ref.commit;
    var val = _ref2.val;
    // commit('setLoading', true, { root: true }) // 调用全局vuex的setLoading方法
    // 需要使用promise用来配合loading
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        commit('onePlus', val);
        resolve(); // commit('setLoading', false, { root: true })
      }, 1500);
    });
  }
};
var mutations = {
  onePlus: function onePlus(state) {
    var val = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    state.number = state.number + val;
  },
  twoPlus: function twoPlus() {
    console.log('twoPlus');
    state.number = state.number + 2;
  }
};
var _default = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations
};
exports.default = _default;